import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, IconButton } from "@mui/material";

import "./style.css";
import Button from "@mui/material/Button";
import FirstPageIcon from "@mui/icons-material/FirstPage.js";
import { useNavigate } from "react-router";
import Spinner from "../../components/Spinner";
import interceptor from "../../interceptors/axios";

const MyData = () => {
  let navigate = useNavigate();
  const [state, setState] = useState([]);
  useEffect(() => {
    (async () => {
      interceptor
        .post(
          "/api/v1/admin/user/get/contract",
          JSON.stringify({
            id: localStorage.getItem("pageId"),
          }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .then((reqst) => setState(reqst?.data));
    })();
  }, []);

  return (
    <Box sx={{ py: 3 }}>
      <div
        style={{
          display: "flex",
          marginBottom: "12px",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <IconButton sx={{ mr: 2, ml: 1 }} onClick={() => navigate(-1)}>
          <FirstPageIcon sx={{ fontSize: "20px" }} />
        </IconButton>
        <Typography variant={"h5"}>Shaxsiy ma'lumotlar</Typography>
      </div>

      {state?.data ? (
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} md={4}>
            <Box className="each_box">
              <span>Maqsadi</span>
              <Typography>{state?.data?.target}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="each_box">
              <span>Turi</span>
              <Typography>{state?.data?.type}</Typography>
            </Box>
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            <Box className="each_box">
              <span>Yuborilgan sanasi</span>
              <Typography>{state?.data?.createdAt.slice(0, 10)}</Typography>
            </Box>
          </Grid>
          {state?.data?.acceptedAt ? (
            <Grid item xs={12} md={4}>
              <Box className="each_box">
                <span>Qabul qilingan sanasi</span>
                <Typography>{state?.data?.acceptedAt.slice(0, 10)}</Typography>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} md={4}>
            <Box className="each_box">
              <span>Qabul qiluvchi</span>
              <Typography>{state?.data?.recipient}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="each_box">
              <span>Holati</span>
              {state?.data?.applicationStatus === "NEW" ? (
                <Typography sx={{ color: "rgb(249,192,4)" }}>
                  {" "}
                  Ro'yxatdan o'tgan{" "}
                </Typography>
              ) : state?.data?.applicationStatus === "ACCEPTED" ? (
                <Typography sx={{ color: "rgb(46, 125, 50)" }}>
                  {" "}
                  Qabul qilingan{" "}
                </Typography>
              ) : state?.data?.applicationStatus === "EXAM" ? (
                <Typography sx={{ color: "rgb(46, 125, 50)" }}>
                  {" "}
                  Imtihonga qabul qilingan{" "}
                </Typography>
              ) : (
                <Typography sx={{ color: "red" }}> Rad etilgan </Typography>
              )}
            </Box>
          </Grid>
          {state?.data?.contractNumber ? (
            <Grid item xs={12} md={4}>
              <Box className="each_box">
                <span>Shartnoma raqami</span>
                <Typography>{state?.data?.contractNumber}</Typography>
              </Box>
            </Grid>
          ) : null}
          {state?.data?.contract?.fullPath ? (
            <Grid item xs={12} md={4}>
              <Box className="each_box">
                <Button href={state?.data?.contract?.fullPath}>
                  shartnomani ko'rish
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Spinner />
      )}
    </Box>
  );
};
export default MyData;
