export const style_add = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 780,
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 12,
  py: 3,
  px: 4,
};
export const style_update = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 780,
  borderRadius: "8px",
  bgcolor: "background.paper",
  py: 3,
  px: 4,
};
export const style_show = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "6px",
  bgcolor: "background.paper",
  py: 3,
  px: 4,
};

export const style_get_one = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  borderRadius: "6px",
  bgcolor: "background.paper",
  py: 3,
  px: 4,
};
