import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import routesPage from "./components/Data/navigate";

// import routes
function App() {
  return (
    <>
      <Routes>
        {routesPage.map((route, index) => (
          <Route path={route.path} element={route.element} key={index} />
        ))}
      </Routes>
      <ToastContainer autoClose="1200" />
    </>
  );
}

export default App;
