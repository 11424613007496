import React from "react";

// redux actions -->
import { useDispatch, useSelector } from "react-redux";

// selection options -->
import Select from "react-select";
import { periodSummer, periodWinter } from "../../components/Data/regions";

// material ui elements -->
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

// routers -->
import { useNavigate } from "react-router";

// icons -->
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { changeGeneration } from "../redux/actions";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { generation } = useSelector((state) => state.FullReducer);
  return (
    <Box px={4} py={3}>
      <Stack direction="row" alignItems="center">
        <Tooltip title="Chiqish">
          <IconButton onClick={() => navigate("/main")} sx={{ mr: 1 }}>
            <FirstPageIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" gutterBottom m="0">
          Settings
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h6" gutterBottom m="0">
          Qabul davrini tanlash
        </Typography>
        {generation === "WINTER_2023" ? (
          <Select
            options={periodSummer}
            placeholder={"2023-yil, Qishki"}
            onChange={(option) => {
              dispatch(changeGeneration(option.value));
            }}
          />
        ) : (
          <Select
            options={periodWinter}
            placeholder={"2023-yil, Yozgi"}
            onChange={(option) => {
              dispatch(changeGeneration(option.value));
            }}
          />
        )}
      </Stack>
    </Box>
  );
};
export default Settings;
