import { GET_DATA, CHANGE_GENERATION, REGESTRATION } from "./types";

const defaultValue = {
  regData: "",
  generation: "SUMMER_2023",
  userInfo: [],
};

const reducer = (state = defaultValue, action) => {
  switch (action.type) {
    case REGESTRATION:
      return {
        ...state,
        regData: action.payload,
      };
    case GET_DATA:
      return {
        ...state,
        userInfo: action.payload,
      };
    case CHANGE_GENERATION:
      return {
        ...state,
        generation: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
