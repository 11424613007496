import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import interceptor from "../../interceptors/axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import "./style.css";
import { LogFetch } from "../redux/actions";

export default function Log() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pwdRef = useRef();
  const roleRef = useRef();
  const [show, setShow] = useState(false);

  const submit = async (e) => {
    await interceptor
      .post(
        "/api/v1/admin/sign/login",
        JSON.stringify({
          username: roleRef.current.value,
          password: pwdRef.current.value,
        })
      )
      .then((response) => {
        if (response?.status === 200) {
          dispatch(LogFetch(response.data));
          localStorage.setItem("access", response?.data?.data?.accessToken);
          localStorage.setItem("refresh", response?.data?.data?.refreshToken);
          localStorage.setItem("role", response?.data?.data?.role);
          localStorage.setItem("generation", "SUMMER_2023");
          navigate("/main");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errorMessage === "bad credentials") {
          toast.error("Noto'g'ri parol kiritilgan!");
        }
      });
  };
  const enterKey = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      submit();
    }
  };

  return (
    <div className="wrapper">
      <div className="box">
        <div className="form">
          <h2>Login</h2>
          <div className="inputBox">
            <input
              type="text"
              required="required"
              ref={roleRef}
              onKeyUp={enterKey}
            />
            <span>Username</span>
            <i />
          </div>
          {show ? (
            <div className="inputBox">
              <input
                type="text"
                required="required"
                id="myInput"
                ref={pwdRef}
                onKeyUp={enterKey}
              />
              <span>Password</span>
              <i />
            </div>
          ) : (
            <div className="inputBox">
              <input
                type="password"
                required="required"
                id="myInput"
                ref={pwdRef}
                onKeyUp={enterKey}
              />
              <span>Password</span>
              <i />
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <input type="checkbox" onClick={() => setShow(!show)} />
            <span style={{ color: "white" }}>Show Password</span>
          </div>
          <br />
          <div>
            <input type="submit" defaultValue="Login" onClick={submit} />
          </div>
        </div>
      </div>
    </div>
  );
}
