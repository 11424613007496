const getDate = (date) => {
  let time = date && date?.$d.toString();
  let month = time.slice(4, 7);
  let year = time.slice(11, 15);
  let day = time.slice(8, 10);
  if (month === "Jan") {
    month = "01";
  } else if (month === "Feb") {
    month = "02";
  } else if (month === "Mar") {
    month = "03";
  } else if (month === "Apr") {
    month = "04";
  } else if (month === "May") {
    month = "05";
  } else if (month === "Jun") {
    month = "06";
  } else if (month === "Jul") {
    month = "07";
  } else if (month === "Aug") {
    month = "08";
  } else if (month === "Sep") {
    month = "09";
  } else if (month === "Oct") {
    month = "10";
  } else if (month === "Nov") {
    month = "11";
  } else {
    month = "12";
  }

  return year + "-" + month + "-" + day;
};
export { getDate };
