import { CHANGE_GENERATION, GET_DATA, REGESTRATION } from "./types";

export const LogFetch = (data) => ({
  type: REGESTRATION,
  payload: data,
});

export const getData = (data) => ({
  type: GET_DATA,
  payload: data,
});

export const changeGeneration = (gen) => ({
  type: CHANGE_GENERATION,
  payload: gen,
});
