import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useNavigate } from "react-router";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { periodSummer, periodWinter } from "../../components/Data/regions";

//* icons
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import SchoolIcon from "@mui/icons-material/School";

//modal
import AddModal from "../../components/modal/add/add";
import { useEffect } from "react";
import { fetchData, fetchOneUserData } from "../redux/globalActions";
import Spinner from "../../components/Spinner";
import ShowModal from "../../components/modal/show/show";
import EditModal from "../../components/modal/update/update";
import GetOneModal from "../../components/modal/oneuser/getoneuser";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const Controller = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.settings);

  const [openModal, setOpenModal] = useState(false);
  const [openModalShower, setOpenModalShower] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [fieldName, setFieldName] = useState("");

  const [showUser, setShowUser] = useState(false);
  const [isShowed, setIsShowed] = useState(false);
  const [showId, setShowId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchData("/api/v1/field/get/all"));
  }, [dispatch]);

  const handleFetchUserData = (url, userId) => {
    dispatch(fetchOneUserData(url, userId));
  };

  let education_type = data || [];
  const searchByName = () => {
    education_type = education_type.filter((el) =>
      el.fieldName.toLowerCase().includes(fieldName.trim().toLowerCase())
    );
    return education_type;
  };
  searchByName();
  if (loading) {
    return <Spinner />;
  }
  return (
    <Box p={3}>
      <Grid container spacing={2} mb={2}>
        <Grid item md={6} xs={12}>
          <Item>
            <Stack direction="row" alignItems="center">
              <Tooltip title="Chiqish">
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                  <FirstPageIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Item>
        </Grid>
        <Grid item md={6} xs={12}>
          <Item>
            <Stack direction={"row"} justifyContent={"end"}>
              <Box>
                <TextField
                  size="small"
                  placeholder="Yo'nalish nomi bilan..."
                  type="text"
                  value={fieldName}
                  onChange={(e) => setFieldName(e.target.value)}
                  sx={{ mr: 1 }}
                />
              </Box>
              {localStorage.getItem("generation") === "WINTER_2023" ? (
                <Select
                  options={periodSummer}
                  placeholder={"2023-yil, Qishki qabul"}
                  onChange={(option) => {
                    localStorage.setItem("generation", option.value);
                  }}
                />
              ) : (
                <Select
                  options={periodWinter}
                  placeholder={"2023-yil, Yozgi qabul"}
                  onChange={(option) => {
                    localStorage.setItem("generation", option.value);
                  }}
                />
              )}
              <Box>
                <Button
                  variant="outlined"
                  sx={{ mx: 1 }}
                  onClick={() => setOpenModal(true)}
                >
                  Qo'shish <AddIcon fontSize="small" />
                </Button>
                {openModal && (
                  <AddModal open={openModal} setOpen={setOpenModal} />
                )}
                <Tooltip title="Konfiguratsiya">
                  <Button
                    color="warning"
                    variant="outlined"
                    onClick={() => navigate("/configuration")}
                  >
                    <SchoolIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Stack>
          </Item>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1000 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Yo'nalishlar</TableCell>
              <TableCell>Bank nomi</TableCell>
              <TableCell>Shartnoma kodi</TableCell>
              <TableCell>Bank akkaunt</TableCell>
              <TableCell align="center">Tahririyat</TableCell>
            </TableRow>
          </TableHead>
          {data?.length ? (
            <TableBody>
              {education_type
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row.fieldName}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.bankName}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.contractCode}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.bankAccount}
                    </TableCell>
                    <TableCell align="center">
                      {row.isVisible ? (
                        <Tooltip title="Ko'rsatish">
                          <IconButton
                            aria-label="visible"
                            onClick={() => {
                              setIsShowed(true);
                              setShowId(row.id);
                              setOpenModalShower(true);
                            }}
                          >
                            <VisibilityIcon fontSize="small" color="warning" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Ko'rsatmaslik">
                          <IconButton
                            aria-label="unvisible"
                            onClick={() => {
                              setShowId(row.id);
                              setOpenModalShower(true);
                            }}
                          >
                            <VisibilityOffIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {openModalShower && (
                        <ShowModal
                          open={openModalShower}
                          setOpen={setOpenModalShower}
                          isShowed={isShowed}
                          identification={showId}
                        />
                      )}
                      <Tooltip title="O'zgartirish">
                        <IconButton
                          color="success"
                          sx={{ mr: 1 }}
                          onClick={() => {
                            handleFetchUserData(
                              "/api/v1/field/get/one",
                              row.id
                            );
                            setOpenEditModal(true);
                            localStorage.setItem("modalId", row.id);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {openEditModal && (
                        <EditModal
                          open={openEditModal}
                          setOpen={setOpenEditModal}
                        />
                      )}
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          handleFetchUserData("/api/v1/field/get/one", row.id);
                          localStorage.setItem("modalId", row.id);
                          setShowUser(true);
                        }}
                      >
                        Batafsil
                      </Button>
                      {showUser && (
                        <GetOneModal open={showUser} setOpen={setShowUser} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <Spinner />
          )}
        </Table>
        <TablePagination
          component="div"
          count={data?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25]}
        />
      </TableContainer>
    </Box>
  );
};

export default Controller;
