import React from "react";
import ReactDOM from "react-dom/client";

// main routes -->
import { BrowserRouter } from "react-router-dom";

// import fetching system -->
import "./interceptors/axios";

// import material ui css -->
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "react-toastify/dist/ReactToastify.css";

// import redux -->
import { Provider } from "react-redux";
import store from "./views/redux";

// import css -->
import "./index.css";

// import main file -->
import App from "./App";

// ****************************************************************
// This project is written by:                                   **
// 1. Abdirauf Tukliyev (Project Manager);                       **
// 2. Temurbek Suvonov (Front end);                              **
// 3. Saydali Murodillayev (Backend);                            **
// ****************************************************************

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
