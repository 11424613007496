import { toast } from "react-toastify";
import interceptor from "../../interceptors/axios";
import {
  addConfigurationFailure,
  addConfigurationRequest,
  addConfigurationSuccess,
  addDataFailure,
  addDataRequest,
  addDataSuccess,
  contractFailure,
  contractRequest,
  contractSuccess,
  fetchConfigurationFailure,
  fetchConfigurationRequest,
  fetchConfigurationSuccess,
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
  fetchUserDataFailure,
  fetchUserDataRequest,
  fetchUserDataSuccess,
  updateDataFailure,
  updateDataRequest,
  updateDataSuccess,
  visibleFailure,
  visibleRequest,
  visibleSuccess,
} from "./settingsActions";

import { success_msg } from "./types";

export const fetchData = (url = "/api/v1/field/get/all") => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const response = await interceptor.post(url, JSON.stringify({}), {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      });
      dispatch(fetchDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};
export const fetchConfiguration = (url = "/api/v1/config") => {
  return async (dispatch) => {
    dispatch(fetchConfigurationRequest());
    try {
      const response = await interceptor.get(url, {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      });
      dispatch(fetchConfigurationSuccess(response.data.data));
    } catch (error) {
      dispatch(fetchConfigurationFailure(error.message));
    }
  };
};

export const addData = (url, data) => {
  return async (dispatch) => {
    dispatch(addDataRequest());
    try {
      const response = await interceptor.post(url, JSON.stringify(data), {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      });
      if (response.status === 200) {
        toast.success(success_msg);
        dispatch(addDataSuccess(response.data));
        fetchData();
      }
    } catch (error) {
      dispatch(addDataFailure(error.message));
    }
  };
};

export const addConfiguration = (url, data) => {
  return async (dispatch) => {
    dispatch(addConfigurationRequest());
    try {
      const response = await interceptor.post(url, JSON.stringify(data), {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      });
      if (response.status === 200) {
        toast.success(success_msg);
        dispatch(addConfigurationSuccess());
      }
    } catch (error) {
      dispatch(addConfigurationFailure(error.message));
    }
  };
};

export const updateData = (url, body) => {
  return async (dispatch) => {
    dispatch(updateDataRequest());
    try {
      const response = await interceptor.post(url, body, {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      });

      if (response.status === 200) {
        toast.success(success_msg);
        dispatch(updateDataSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(updateDataFailure(error.message));
    }
  };
};

export const fetchOneUserData = (url, userId) => {
  return async (dispatch) => {
    dispatch(fetchUserDataRequest());
    try {
      const response = await interceptor.post(
        url,
        JSON.stringify({
          id: userId,
        }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      );
      dispatch(fetchUserDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchUserDataFailure(error.message));
    }
  };
};
// * is not completed yet
export const addContract = (body) => {
  return async (dispatch) => {
    dispatch(contractRequest());
    try {
      const response = await interceptor.post(
        body.url,
        JSON.stringify({
          uuid: body.uuid,
          amount: body.amount,
        }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      );

      if (response.status === 200) {
        toast.success(success_msg);
        dispatch(contractSuccess(response.data));
      }
    } catch (error) {
      dispatch(contractFailure(error.message));
    }
  };
};

export const changeFieldStatus = (body) => {
  return async (dispatch) => {
    dispatch(visibleRequest());
    try {
      const response = await interceptor.post(
        body.url,
        JSON.stringify({
          id: body.id,
          active: body.active,
        }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      );

      if (response.status === 200) {
        toast.success(success_msg);
        dispatch(visibleSuccess(response.data));
      }
    } catch (error) {
      dispatch(visibleFailure(error.message));
    }
  };
};
