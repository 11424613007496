import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import "../SMSHistory/style.css";

import { useNavigate } from "react-router";

import FirstPageIcon from "@mui/icons-material/FirstPage";

import ConfigurationModal from "../../components/modal/add/configuration";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { fetchConfiguration } from "../redux/globalActions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "start",
  color: theme.palette.text.secondary,
}));
const Configuration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [configurationOpen, setConfigurtionOpen] = useState(false);
  const { config, loading } = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(fetchConfiguration("/api/v1/config"));
  }, [dispatch]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <Box px={3} py={2}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems="center">
              <Tooltip title="Chiqish">
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                  <FirstPageIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5" gutterBottom m="0">
                Parametrlar
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="outlined"
                sx={{ mx: 2 }}
                onClick={() => setConfigurtionOpen(true)}
              >
                Tahrirlash
              </Button>

              {configurationOpen && (
                <ConfigurationModal
                  open={configurationOpen}
                  setOpen={setConfigurtionOpen}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ px: 3, py: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Item className="whole">
                  <Box className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Nomi:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.name}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Manzili:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.address}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Imtihon manzili:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.examPlace}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Imtihon vaqti:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.examDate}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Kenglik:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.longitude}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Uzunlik:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.latitude}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Raqami:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.phone}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      O'quv yili:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.currentStudyYear}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Kim tomonidan o'zgartirilgan:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.updatedBy}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Telegram:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.telegram}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Instagram:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.instagram}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Email:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.mail}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Youtube:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.youtube}
                    </Typography>
                  </Box>
                  <Box sx={{ my: 1 }} className="whole-wrapper">
                    <Typography className="whole-wrapper-title-bold">
                      Facebook:
                    </Typography>
                    <Typography className="whole-wrapper-title">
                      {config.facebook}
                    </Typography>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Configuration;
