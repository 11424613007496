import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, IconButton, Paper, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import "../SMSHistory/style.css";
import Spinner from "../../components/Spinner";
import interceptor from "../../interceptors/axios";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "start",
  color: theme.palette.text.secondary,
}));
const SuggestionHistory = () => {
  let navigate = useNavigate();
  const [history, setHistory] = useState([]);
  useEffect(() => {
    (async () => {
      const { data } = await interceptor
        .post(
          "/api/v1/admin/user/get/ruxsatnoma/list",
          JSON.stringify({
            id: localStorage.getItem("pageId"),
          }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/");
          }
        });
      setHistory(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {history?.data ? (
        <Box sx={{ p: 3, flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              marginBottom: "12px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ mr: 2, ml: 2 }} onClick={() => navigate(-1)}>
                <FirstPageIcon />
              </IconButton>
              <Typography variant={"h5"}>Ruxsatnomalar tarixi</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  mt: 1,
                  mb: 2,
                  fontWeight: 700,
                  fontSize: "20px",
                  textAlign: "end",
                  margin: "0 28px 0 0",
                }}
              >
                Yuborilgan xabarlar soni: {history?.data.length}ta
              </Typography>
            </Box>
          </Box>

          <Box sx={{ px: 3, py: 1 }}>
            <Grid container spacing={2}>
              {history?.data.map((elements) => (
                <Grid item xs={12} md={6} key={elements?.createdAt}>
                  <Item className="whole">
                    <Box>
                      <Box className="whole-wrapper">
                        <Typography className="whole-wrapper-title-bold">
                          Sanasi (vaqti bilan):
                        </Typography>
                        <Typography className="whole-wrapper-title">
                          {elements?.createdAt}
                        </Typography>
                      </Box>
                      <Box sx={{ my: 1 }} className="whole-wrapper">
                        <Typography className="whole-wrapper-title-bold">
                          IDsi:
                        </Typography>
                        <Typography className="whole-wrapper-title">
                          {elements?.resAttachment?.id}
                        </Typography>
                      </Box>
                      <Box sx={{ my: 1 }} className="whole-wrapper">
                        <Typography className="whole-wrapper-title-bold">
                          Nomi:
                        </Typography>
                        <Typography className="whole-wrapper-title">
                          {elements?.resAttachment?.originalName}
                        </Typography>
                      </Box>
                      <Box sx={{ my: 1 }} className="whole-wrapper">
                        <Typography className="whole-wrapper-title-bold">
                          Linki:
                        </Typography>
                        <Typography className="whole-wrapper-title">
                          {elements?.resAttachment?.fullPath}
                        </Typography>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ) : (
        <Spinner />
      )}
    </>
  );
};
export default SuggestionHistory;
