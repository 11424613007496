import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { IconButton, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TooltipMui from "@mui/material/Tooltip";
import "./style.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Bar,
  BarChart,
  PieChart,
  Pie,
  Sector,
  Cell,
  Tooltip,
} from "recharts";
import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";
import interceptor from "../../interceptors/axios";

const div = {
  display: "flex",
  alignItems: "center",
  gap: "24px",
};
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 12) * cos;
  const sy = cy + (outerRadius + 12) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 40;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 12}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={4} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Talabalar: ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Foizda ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
const COLORS = ["#00C49F", "#FF8042", "#FFBB28", "#0088FE", "#3de712"];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Statistics() {
  let datas = [];
  let linedata = [];
  let datapie = [];
  let datacard = [];
  let navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [date, setDate] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs("2023-01-01"));
  const [endDate, setEndDate] = useState(dayjs("2023-08-31"));
  const [userData, setUserData] = useState([]);
  const [month, setMonth] = useState([]);
  const [studyTypeHandler, setStudyTypeHandler] = useState("MAGISTR");
  const [fieldTypeHandler, setFieldTypeHandler] = useState("KUNDUZGI");
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const handleExcel = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    await interceptor
      .post(
        "/api/v1/file/download/excel/v2",
        JSON.stringify({ generations: [localStorage.getItem("generation")] }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        let blob = new Blob([response?.data], {
          type: "application/vnd.ms-excel; charset=utf-8",
        });
        saveAs(blob, "users-data.xlsx");
        setSubmitting(false);
        toast.success("Excel yuklandi!");
      });
  };

  const getDate = (date) => {
    let time = date.$d.toString();
    let month = time.slice(4, 7);
    let year = time.slice(11, 15);
    let day = time.slice(8, 10);
    if (month === "Jan") {
      month = "01";
    } else if (month === "Feb") {
      month = "02";
    } else if (month === "Mar") {
      month = "03";
    } else if (month === "Apr") {
      month = "04";
    } else if (month === "May") {
      month = "05";
    } else if (month === "Jun") {
      month = "06";
    } else if (month === "Jul") {
      month = "07";
    } else if (month === "Aug") {
      month = "08";
    } else if (month === "Sep") {
      month = "09";
    } else if (month === "Oct") {
      month = "10";
    } else if (month === "Nov") {
      month = "11";
    } else {
      month = "12";
    }

    return year + "-" + month + "-" + day;
  };
  useEffect(() => {
    (async () => {
      await interceptor
        .post(
          "/api/v1/admin/statistics/user",
          JSON.stringify({ generation: [localStorage.getItem("generation")] }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            setUserData(res?.data);
          }
        })
        .catch((error) => console.log(error));
    })();
  }, []);

  const [opacity, setOpacity] = useState({
    "Ro'yxatdan o'tganlar": 1,
    "Qabul qilinganlar": 1,
    "Imtihonga tavsiya qilinganlar": 1,
    "To'lov qilganlar": 1,
    "Rad etilganlar": 1,
  });

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0.5 });
    },
    [opacity, setOpacity]
  );

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );

  useEffect(() => {
    (async () => {
      await interceptor
        .post(
          "/api/v1/admin/statistics/register/custom",
          JSON.stringify({ to: getDate(endDate), from: getDate(startDate) }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            setDate(res?.data);
          }
        })
        .catch((error) => console.log(error));
    })();
  }, [endDate, startDate]);

  useEffect(() => {
    (async () => {
      interceptor
        .post(
          "/api/v1/admin/statistics/register/monthly",
          JSON.stringify({ generation: [localStorage.getItem("generation")] }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .then((response) => setMonth(response?.data))
        .catch((error) => console.log(error));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await interceptor
        .post(
          "/api/v1/admin/statistics/field/by/field",
          JSON.stringify({
            studyType: studyTypeHandler,
            fieldType: fieldTypeHandler,
            generations: [localStorage.getItem("generation")],
          }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .then((response) => setTypeData(response?.data))
        .catch((error) => console.log(error));
    })();
  }, [studyTypeHandler, fieldTypeHandler]);

  const databar = async () => {
    for (let i = 0; i < typeData?.data?.fields.length; i++) {
      datas.push({
        name: typeData?.data?.fields[i]?.name,
        "Ro'yxatdan o'tganlar": typeData?.data?.fields[i]?.acceptance?.waiting,
        "Rad etilganlar": typeData?.data?.fields[i]?.acceptance?.rejected,
        "Imtihonga tavsiya qilinganlar":
          typeData?.data?.fields[i]?.acceptance?.exam,
        "To'lov qilganlar": typeData?.data?.fields[i]?.acceptance?.payed,
        "Qabul qilinganlar": typeData?.data?.fields[i]?.acceptance?.accepted,
      });
    }
  };
  databar();

  const linegraph = async () => {
    for (let i = month?.data?.dailyList.length - 1; i >= 0; i--) {
      linedata.push({
        day: month?.data?.dailyList[i]?.day,
        "Ro'yxatdan o'tganlar": month?.data?.dailyList[i]?.acceptance?.waiting,
        "Rad etilganlar": month?.data?.dailyList[i]?.acceptance?.rejected,
        "To'lov qilganlar": month?.data?.dailyList[i]?.acceptance?.payed,
        "Imtihonga tavsiya qilinganlar":
          month?.data?.dailyList[i]?.acceptance?.exam,
        "Qabul qilinganlar": month?.data?.dailyList[i]?.acceptance?.accepted,
      });
    }
  };
  linegraph();

  const changeWords = (data) => {
    if (data === "waiting") {
      data = "Ro'yxatdan o'tganlar";
    } else if (data === "rejected") {
      data = "Rad etilganlar";
    } else if (data === "accepted") {
      data = "Qabul qilinganlar";
    } else if (data === "payed") {
      data = "To'lov qilganlar";
    } else if (data === "exam") {
      data = "Imtihonga tavsiya qilinganlar";
    }
    return data;
  };

  const piechart = async () => {
    if (!date?.data?.acceptance) {
      return; // Ma'lumotlar mavjud emasligini tekshirish
    }

    for (let elem in Object?.values(date?.data?.acceptance)) {
      datapie?.push({
        value: Object?.values(date?.data?.acceptance)[elem],
        name: changeWords(Object?.keys(date?.data?.acceptance)[elem]),
      });
    }
  };

  piechart();

  const cardchart = async () => {
    datacard.push({
      name: "Ta'lim turi bo'yicha:",
      Magistr: date?.data?.registeredUsers?.master?.types[0]?.count,
      Bakalavr: date?.data?.registeredUsers?.bachelor?.types[1]?.count,
      "Bakalavr(maxsus sirtqi)":
        date?.data?.registeredUsers?.bachelor?.types[0]?.count,
      "Bakalavr(kunduzgi)":
        date?.data?.registeredUsers?.bachelor?.types[2]?.count,
    });
  };
  cardchart();

  return (
    <>
      {userData?.data ? (
        <Box sx={{ py: 3, px: 4 }}>
          <Grid container spacing={4} sx={{ mb: 3 }}>
            <Grid item md={12} xs={12}>
              <TooltipMui title="Chiqish">
                <IconButton onClick={() => navigate("/main")} sx={{ mr: 1 }}>
                  <FirstPageIcon />
                </IconButton>
              </TooltipMui>
              <Button
                color="warning"
                variant="outlined"
                onClick={handleExcel}
                sx={{ px: 3, py: 1, ml: 2 }}
                disabled={submitting}
              >
                {submitting ? "yuklanmoqda..." : "excelni yuklash"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={4} sx={{ px: 2 }}>
            <Grid item xs={6} md={3}>
              <Item sx={{ background: "#F8F8F8" }} className="items-card">
                <Box sx={{ py: 2, px: 3 }}>
                  <Typography sx={{ fontSize: "34px", fontWeight: "600" }}>
                    {userData?.data?.totalUsers?.totalUsers}{" "}
                    <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                      talabalar
                    </Typography>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={6} md={3}>
              <Item
                sx={{ background: "#F8F8F8" }}
                className="items-card"
                onClick={() => {
                  setStudyTypeHandler("BAKALAVR");
                  setFieldTypeHandler("MAXSUS_SIRTQI");
                }}
              >
                <Box sx={{ py: 2, px: 3 }}>
                  <Typography sx={{ fontSize: "34px", fontWeight: "600" }}>
                    {userData?.data?.study?.bachelor?.types?.MAXSUS_SIRTQI}{" "}
                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                      bakalavr <span>(maxsus)</span>
                    </Typography>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            <Grid
              item
              xs={6}
              md={
                localStorage.getItem("generation") === "WINTER_2023" ? "3" : "2"
              }
            >
              <Item
                sx={{ background: "#F8F8F8" }}
                className="items-card"
                onClick={() => {
                  setStudyTypeHandler("BAKALAVR");
                  setFieldTypeHandler("SIRTQI");
                }}
              >
                <Box sx={{ py: 2, px: 3 }}>
                  <Typography sx={{ fontSize: "34px", fontWeight: "600" }}>
                    {userData?.data?.study?.bachelor?.types?.SIRTQI}{" "}
                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                      bakalavr (sirtqi)
                    </Typography>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            {localStorage.getItem("generation") === "SUMMER_2023" && (
              <Grid item xs={6} md={2}>
                <Item
                  sx={{ background: "#F8F8F8" }}
                  className="items-card"
                  onClick={() => {
                    setStudyTypeHandler("BAKALAVR");
                    setFieldTypeHandler("KUNDUZGI");
                  }}
                >
                  <Box sx={{ py: 2, px: 3 }}>
                    <Typography sx={{ fontSize: "34px", fontWeight: "600" }}>
                      {userData?.data?.study?.bachelor?.types?.KUNDUZGI}{" "}
                      <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                        bakalavr
                      </Typography>
                    </Typography>
                  </Box>
                </Item>
              </Grid>
            )}
            <Grid
              item
              xs={6}
              md={
                localStorage.getItem("generation") === "WINTER_2023" ? "3" : "2"
              }
            >
              <Item
                sx={{ background: "#F8F8F8" }}
                className="items-card"
                onClick={() => {
                  setStudyTypeHandler("MAGISTR");
                  setFieldTypeHandler("KUNDUZGI");
                }}
              >
                <Box sx={{ py: 2, px: 3 }}>
                  <Typography sx={{ fontSize: "34px", fontWeight: "600" }}>
                    {userData?.data?.study?.master?.types?.KUNDUZGI}{" "}
                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                      magistr
                    </Typography>
                  </Typography>
                </Box>
              </Item>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ my: 5 }}>
            <Grid xs={12} md={6}>
              <Item>
                <div>
                  <LineChart
                    width={680}
                    height={470}
                    data={linedata}
                    margin={{
                      top: 20,
                      right: 30,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3 3" />
                    <XAxis dataKey="day" />
                    <YAxis />
                    <YAxis />
                    <Tooltip />
                    <Legend
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />

                    <Line
                      type="monotone"
                      dataKey="Ro'yxatdan o'tganlar"
                      strokeOpacity={opacity["Ro'yxatdan o'tganlar"]}
                      stroke="#1BA8F0"
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="Rad etilganlar"
                      strokeOpacity={opacity["Rad etilganlar"]}
                      stroke="#ff5722"
                    />

                    <Line
                      type="monotone"
                      dataKey="Qabul qilinganlar"
                      strokeOpacity={opacity["Qabul qilinganlar"]}
                      stroke="#76ff03"
                      activeDot={{ r: 4 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="Imtihonga tavsiya qilinganlar"
                      strokeOpacity={opacity["Imtihonga tavsiya qilinganlar"]}
                      stroke="orange"
                      activeDot={{ r: 6 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="To'lov qilganlar"
                      strokeOpacity={opacity["To'lov qilganlar"]}
                      stroke="#008000"
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </div>
              </Item>
            </Grid>
            <Grid xs={12} md={6}>
              <Item>
                <BarChart
                  width={680}
                  height={470}
                  data={datas}
                  margin={{
                    top: 20,
                    right: 30,
                    bottom: 10,
                  }}
                  barSize={40}
                >
                  <CartesianGrid strokeDasharray="3 3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="To'lov qilganlar" stackId="a" fill="#597D35" />
                  <Bar dataKey="Rad etilganlar" stackId="a" fill="#ff5722" />
                  <Bar
                    dataKey="Ro'yxatdan o'tganlar"
                    stackId="a"
                    fill="#1BA8F0"
                  />
                  <Bar dataKey="Qabul qilinganlar" stackId="a" fill="#76ff03" />
                  <Bar
                    dataKey="Imtihonga tavsiya qilinganlar"
                    stackId="a"
                    fill="orange"
                  />
                </BarChart>
              </Item>
            </Grid>
          </Grid>
          <Grid container sx={{ py: 4, mt: 4 }}>
            <Grid xs={12} md={6}>
              <Box sx={div}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start"
                    value={startDate}
                    inputFormat={"DD/MM/YYYY"}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End"
                    value={endDate}
                    inputFormat={"DD/MM/YYYY"}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} md={6}>
              <Box sx={{ padding: "60px 0" }}>
                <BarChart
                  width={670}
                  height={480}
                  data={datacard}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3 3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Magistr" stackId="a" fill="#8884d8" />
                  <Bar dataKey="Bakalavr" stackId="b" fill="#82ca9d" />
                  <Bar
                    dataKey="Bakalavr(maxsus sirtqi)"
                    stackId="c"
                    fill="#ff7300"
                  />
                  <Bar
                    dataKey="Bakalavr(kunduzgi)"
                    stackId="d"
                    fill="#C94027"
                  />
                </BarChart>
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <PieChart width={680} height={470}>
                <Pie
                  data={datapie}
                  cx={300}
                  cy={250}
                  innerRadius={130}
                  outerRadius={160}
                  fill="#8884d8"
                  paddingAngle={5}
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {datapie.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Spinner />
      )}
    </>
  );
}
