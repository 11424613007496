import React, { useEffect, useState } from "react";
import { styled } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import Grid from "@mui/joy/Grid";
import { Box } from "@mui/joy";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

// navigation imports-->
import { useNavigate } from "react-router";

//selection options -->
import Select from "react-select";

// import files -->
import interceptor from "../../interceptors/axios";
import Spinner from "../../components/Spinner";

// selection data -->
import {
  category,
  educationType,
  sorting_data,
} from "../../components/Data/regions.js";

// import icons
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { columns } from "../../components/Data/constant";
import Cookies from "js-cookie";

const Item = styled(Sheet)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.vars.palette.text.tertiary,
}));

function MainPage() {
  const navigate = useNavigate();

  const [page, setPage] = useState(parseInt(Cookies.get("cookiePage")) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [zone, setZone] = useState(null);
  const [studyType, setStudyType] = useState(null);
  const [userFullName, setUserFullName] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [sorting, setSorting] = useState("CREATED_AT");
  const [orderByNumber, setOrderByNumber] = useState(
    Cookies.get("asc") || false
  );

  Cookies.set("cookiePage", page, { expires: 3, secure: true });
  Cookies.set("asc", orderByNumber, { expires: 3, secure: true });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // * filter function -->
  const handleFunc = async (e) => {
    const { data } = await interceptor.post(
      "/api/v1/admin/user/filter",
      JSON.stringify({
        firstName: firstName.trim(),
        phone,
        page,
        size: rowsPerPage,
        generation: localStorage.getItem("generation"),
      }),
      {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      }
    );
    setPhone("");
    setFirstName("");
    setUserFullName(data?.data);
  };

  // * refresh function -->
  const handleRefresh = async (e) => {
    e.preventDefault();
    setFirstName("");
    setPhone("");
    const { data } = await interceptor.post(
      "/api/v1/admin/user/filter",
      JSON.stringify({
        generation: localStorage.getItem("generation"),
      }),
      {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      }
    );
    setUserFullName(data?.data);
    setZone(null);
    setStudyType(null);
    setSorting("CREATED_AT");
    setOrderByNumber(true);
  };

  const enter = (e) => {
    e.preventDefault();

    if (e.keyCode === 13) {
      handleFunc();
    }
  };

  // *users data functions -->
  useEffect(() => {
    (async () => {
      const { data } = await interceptor
        .post(
          "/api/v1/admin/user/filter",
          JSON.stringify({
            firstName,
            page: page,
            size: rowsPerPage,
            zone,
            studyType,
            generation: localStorage.getItem("generation"),
            orderBy: sorting,
            asc: orderByNumber,
          }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/");
          }
        });
      setUserFullName(data?.data);
    })();
  }, [
    orderByNumber,
    page,
    rowsPerPage,
    zone,
    studyType,
    sorting,
    firstName,
    navigate,
  ]);

  return (
    <Box sx={{ py: 2, px: 5 }}>
      <Grid container spacing={1} sx={{ flexGrow: 1, py: 3 }}>
        <Grid xs={12} md={2}>
          <Item>
            <TextField
              size="small"
              placeholder="Telefon nomeri bilan..."
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ width: "100%" }}
              onKeyUp={enter}
            />
          </Item>
        </Grid>
        <Grid xs={12} md={2}>
          <Item>
            <TextField
              size="small"
              placeholder="Ismi bilan..."
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ width: "100%" }}
              onKeyUp={enter}
            />
          </Item>
        </Grid>
        <Grid xs={12} md={2}>
          <Item sx={{ zIndex: "999" }}>
            <Select
              options={educationType}
              placeholder={"Ta'lim turini tanlang..."}
              onChange={(option) => setStudyType(option.value)}
            />
          </Item>
        </Grid>
        <Grid xs={12} md={2}>
          <Item sx={{ zIndex: "998" }}>
            <Select
              options={category}
              placeholder={"Holatini tanlang..."}
              onChange={(option) => setZone(option.value)}
            />
          </Item>
        </Grid>
        <Grid xs={12} md={2}>
          <Item sx={{ zIndex: "998" }}>
            <Select
              options={sorting_data}
              placeholder={"Sortlash..."}
              onChange={(option) => setSorting(option.value)}
            />
          </Item>
        </Grid>
        <Grid xs={12} md={2}>
          <Item>
            {localStorage.getItem("role") === "SUPER_ADMIN" && (
              <Tooltip title="Statistika">
                <Button
                  variant="outlined"
                  color="warning"
                  sx={{ margin: "0 0 0 4px" }}
                  onClick={() => navigate("/statistics")}
                >
                  <InsertChartIcon />
                </Button>
              </Tooltip>
            )}
            {localStorage.getItem("role") === "SUPER_ADMIN" && (
              <Tooltip title="Parametrlar">
                <Button
                  color="secondary"
                  variant="outlined"
                  sx={{ margin: "0 0 0 4px" }}
                  onClick={() => {
                    navigate("/controller");
                  }}
                >
                  <SettingsApplicationsIcon />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="Yangilash ">
              <IconButton
                color="success"
                sx={{ margin: "0 0 0 4px" }}
                onClick={handleRefresh}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
            {orderByNumber ? (
              <Tooltip title="Saralash">
                <IconButton
                  aria-label={"icon up"}
                  sx={{ margin: "0 0 0 4px" }}
                  onClick={() => setOrderByNumber(false)}
                >
                  {" "}
                  <ArrowUpwardIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Saralash">
                <IconButton
                  aria-label={"icon down"}
                  sx={{ margin: "0 0 0 4px" }}
                  onClick={() => setOrderByNumber(true)}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Tooltip>
            )}
          </Item>
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden", px: 1 }}>
        <TableContainer sx={{ maxHeight: 1500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                      {" "}
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userFullName?.content ? (
                userFullName?.content.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => navigate(`/main/${row.id}`)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value === "YELLOW" ? (
                              <Typography
                                variant={"subtitle1"}
                                gutterBottom
                                sx={{ color: "warning.main" }}
                              >
                                Ro'yxatdan o'tgan
                              </Typography>
                            ) : value === "RED" ? (
                              <Typography
                                variant={"subtitle1"}
                                gutterBottom
                                sx={{ color: "error.main" }}
                              >
                                Rad qilingan
                              </Typography>
                            ) : value === "GREEN" ? (
                              <Typography
                                variant={"subtitle1"}
                                gutterBottom
                                sx={{ color: "success.main" }}
                              >
                                Qabul qilingan
                              </Typography>
                            ) : value === "ORANGE" ? (
                              <Typography
                                variant={"subtitle1"}
                                gutterBottom
                                sx={{ color: "info.main" }}
                              >
                                Imtihonga tavsiya qilindi
                              </Typography>
                            ) : value === "WHITE" ? (
                              <Typography
                                variant={"subtitle1"}
                                gutterBottom
                                sx={{ color: "secondary.main" }}
                              >
                                To'lagan
                              </Typography>
                            ) : column.id === "ruxsatnomaSend" ? (
                              value ? (
                                <Button variant="outlined" color="success">
                                  Ha
                                </Button>
                              ) : (
                                <Button variant="outlined" color="error">
                                  Yo'q
                                </Button>
                              )
                            ) : column.id === "examResult" ? (
                              value ? (
                                value
                              ) : (
                                <Button disabled>Mavjud emas</Button>
                              )
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <Spinner />
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={userFullName?.paging?.totalItems || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default MainPage;
