import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";

import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Spinner from "../../components/Spinner";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import interceptor from "../../interceptors/axios";
import { getData } from "../redux/actions";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PaidIcon from "@mui/icons-material/Paid";

import "./user.css";
import ContractSum from "../../components/modal/contract-sum/contract-sum";
const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[500] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#52b202",
    },
    secondary: {
      main: "#ffab00",
    },
    additional: {
      main: "#283593",
    },
  },
});

const User = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  localStorage.setItem("pageId", id);
  const [oneUserData, setOneUserData] = useState(null);
  const [comment, setComment] = useState("");
  const [change, setChange] = useState(true);
  const [result, setResult] = useState("");
  const [contractSum, setContractSum] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await interceptor
        .post(
          "/api/v1/admin/user/get/one",
          JSON.stringify({
            id: id,
          }),
          {
            headers: {
              "X-Admin-Token": localStorage.getItem("access"),
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/");
          }
        });
      setOneUserData(data?.data);
      dispatch(getData(data?.data));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, change]);
  const sendContract = async (e) => {
    e.preventDefault();
    await interceptor
      .post("/api/v1/admin/user/send/contract", JSON.stringify({ uuid: id }), {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      })
      .then((req) => {
        if (req.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli");
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage === "applicant status is not NEW")
          return toast.error("Qayta tahrirlang");
        if (error.response.data.errorMessage === "applicant status is not EXAM")
          return toast.error("Holati mos emas");
      });
  };
  const sendRegenerateContract = async (e) => {
    e.preventDefault();
    await interceptor
      .post(
        "/api/v1/admin/user/regenerate/contract",
        JSON.stringify({ uuid: id }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      )
      .then((req) => {
        if (req.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli");
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage === "applicant status is not NEW")
          return toast.error("Qayta tahrirlang");
        if (error.response.data.errorMessage === "applicant status is not EXAM")
          return toast.error("Holati mos emas");
      });
  };
  const sendMessage = async (e) => {
    e.preventDefault();

    if (!comment.trim()) {
      return toast.info("Xabar yozing!");
    }

    await interceptor
      .post(
        "/api/v1/admin/user/send/sms",
        JSON.stringify({ uuid: id, text: comment }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      )
      .then((req) => {
        if (req.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const acceptUser = async (e) => {
    e.preventDefault();
    await interceptor
      .post("/api/v1/admin/user/accept", JSON.stringify({ uuid: id }), {
        headers: {
          "X-Admin-Token": localStorage.getItem("access"),
        },
      })
      .then((req) => {
        if (req.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli");
        }
      })
      .catch((error) => {
        if (
          error.response.data.errorMessage === "applicant status is not NEW"
        ) {
          toast.error("Qayta tahrirlang");
        }
      });
  };
  const rejectUser = async (e) => {
    e.preventDefault();
    await interceptor
      .post(
        "/api/v1/admin/user/reject",
        JSON.stringify({ uuid: id, comment }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      )
      .then((req) => {
        if (req.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli");
        }
      });
  };
  const sendRuxsatnoma = async (e) => {
    e.preventDefault();
    await interceptor
      .post(
        "/api/v1/admin/user/send/ruxsatnoma",
        JSON.stringify({
          id,
        }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      )
      .then((reqs) => {
        if (reqs.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli");
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage === "applicant status is not NEW")
          return toast.error("Qayta tahrirlang");
        if (error.response.data.errorMessage === "applicant status is not EXAM")
          return toast.error("Holati mos emas");
      });
  };
  const sendScore = async (event) => {
    event.preventDefault();
    if (!result.length > 0) {
      return toast.info("Ballni kiriting");
    }
    await interceptor
      .post(
        "/api/v1/admin/user/set/exam/result",
        JSON.stringify({
          uuid: id,
          result,
        }),
        {
          headers: {
            "X-Admin-Token": localStorage.getItem("access"),
          },
        }
      )
      .then((reqs) => {
        if (reqs.status === 200) {
          setChange(!change);
          toast.success("Muvaffaqiyatli yuborildi");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <ThemeProvider theme={theme}>
      {oneUserData?.userInfo?.profilePhoto?.fullPath ? (
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={9}>
              <Item>
                <Stack
                  direction={"row"}
                  mb={2}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"}>
                    <Tooltip title="Chiqish">
                      <IconButton
                        onClick={() => navigate("/main")}
                        sx={{ mr: 2 }}
                      >
                        <FirstPageIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography variant="h5" gutterBottom sx={{ mr: 3 }}>
                      Talaba haqida ma'lumotlar
                    </Typography>
                    {oneUserData?.userInfo?.zone === "ORANGE" ? (
                      <Button color={"primary"} variant="outlined">
                        Imtihonga tavsiya etilgan
                      </Button>
                    ) : oneUserData?.userInfo?.zone === "RED" ? (
                      <Button color={"error"} variant="outlined">
                        {" "}
                        Rad etilgan
                      </Button>
                    ) : oneUserData?.userInfo?.zone === "YELLOW" ? (
                      <Button color="warning" variant="outlined" size="small">
                        {" "}
                        Ro'yxatdan o'tgan
                      </Button>
                    ) : (
                      <Button color={"success"} variant="outlined">
                        Qabul qilingan
                      </Button>
                    )}
                  </Stack>
                  <Stack direction={"row"}>
                    {oneUserData?.userInfo?.zone === "GREEN" ||
                    oneUserData?.userInfo?.zone === "WHITE" ? (
                      <Tooltip title="Kontrakt">
                        <IconButton onClick={() => setContractSum(true)}>
                          <PaidIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {contractSum ? (
                      <ContractSum
                        open={contractSum}
                        setOpen={setContractSum}
                        identification={id}
                        setChange={setChange}
                      />
                    ) : null}
                    <Tooltip title="Shartnoma holati">
                      <IconButton
                        sx={{ margin: "0px 4px" }}
                        variant="outlined"
                        color="additional"
                        onClick={() => navigate("/mydata")}
                      >
                        <AssignmentIndIcon />{" "}
                      </IconButton>
                    </Tooltip>

                    {oneUserData?.fieldInfo.fieldType !== "MAXSUS_SIRTQI" && (
                      <Tooltip title="Ruxsatnoma tarixi">
                        <IconButton
                          sx={{ margin: "0px 4px" }}
                          color="warning"
                          variant="outlined"
                          onClick={() => navigate("/receive-history")}
                        >
                          <InsertDriveFileIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Sms tarixi">
                      <IconButton
                        sx={{ margin: "0px 4px" }}
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate("/history")}
                      >
                        <EmailIcon />
                      </IconButton>
                    </Tooltip>

                    {oneUserData?.userInfo?.zone === "RED" ||
                    oneUserData?.userInfo?.zone === "YELLOW" ||
                    oneUserData?.userInfo?.zone === "ORANGE" ? (
                      <Tooltip title="Tahrirlash">
                        <IconButton
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate("/update")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Stack>
                </Stack>
                {oneUserData ? (
                  <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                      <img
                        src={oneUserData?.userInfo?.profilePhoto?.fullPath}
                        alt="user-img"
                        className="image-user"
                        loading="lazy"
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Talaba F.I.O</span>
                        <Typography>
                          {oneUserData?.userInfo?.lastName +
                            " " +
                            oneUserData?.userInfo?.firstName +
                            " " +
                            oneUserData?.userInfo?.fatherName}
                        </Typography>
                      </div>
                      <div className={"each_input margin"}>
                        <span>Telefon raqami</span>
                        <Typography>{oneUserData?.userInfo?.phone}</Typography>
                      </div>
                      <div className={"each_input margin"}>
                        <span>Ta'lim turi</span>
                        <Typography>
                          {oneUserData?.fieldInfo?.fieldType}
                        </Typography>
                      </div>

                      <div className={"each_input margin"}>
                        <span>Ta'lim turi kodi</span>
                        <Typography>
                          {oneUserData?.fieldInfo?.fieldCode}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input "}>
                        <span>Ta'lim turi</span>
                        <Typography>
                          {oneUserData?.fieldInfo?.studyType}
                        </Typography>
                      </div>
                      <div className={"each_input margin"}>
                        <span>Tanlangan yo'nalish</span>
                        <Typography>
                          {oneUserData?.fieldInfo?.fieldName}
                        </Typography>
                      </div>
                      <div className={"each_input margin"}>
                        <span>Kontrakt narxi</span>
                        <Typography>
                          {oneUserData?.fieldInfo?.contractCost +
                            " (" +
                            oneUserData?.fieldInfo?.contractCostInLetters +
                            ")"}
                        </Typography>
                      </div>
                      <div className={"each_input margin"}>
                        <span>Ta'lim muddati</span>
                        <Typography>
                          {oneUserData?.fieldInfo?.duration} yil
                        </Typography>
                      </div>
                    </Grid>

                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Fuqaroligi</span>
                        <Typography>
                          {oneUserData?.passportInfo?.citizenship}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Millati</span>
                        <Typography>
                          {oneUserData?.passportInfo?.nationality}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Tug'ilgan sanasi</span>
                        <Typography>
                          {oneUserData?.passportInfo?.birthDate}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Passport seria raqami</span>
                        <Typography>
                          {oneUserData?.passportInfo?.docSerial +
                            " " +
                            oneUserData?.passportInfo?.docNumber}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Jinsi</span>
                        <Typography>
                          {oneUserData?.passportInfo?.gender === "MALE"
                            ? "Erkak"
                            : "Ayol"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Manzili</span>
                        <Typography>
                          {oneUserData?.passportInfo?.region +
                            ", " +
                            oneUserData?.passportInfo?.district +
                            ", " +
                            oneUserData?.passportInfo?.streetAndHome}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Passport kim tamonidan berilgan</span>
                        <Typography>
                          {oneUserData?.passportInfo?.docGivenBy}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Passport berilgan sana</span>
                        <Typography>
                          {oneUserData?.passportInfo?.docGivenDate}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Ma'lumoti</span>
                        <Typography>
                          {oneUserData?.diplomaInfo?.malumoti}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Tugatgan muassasa nomi</span>
                        <Typography>
                          {oneUserData?.diplomaInfo?.muassasaNomi}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Muassasa turi</span>
                        <Typography>
                          {oneUserData?.diplomaInfo?.muassasaTuri}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Diplom seriasi</span>
                        <Typography>
                          {oneUserData?.diplomaInfo?.diplomaSerial +
                            " " +
                            oneUserData?.diplomaInfo?.diplomaNumber}
                        </Typography>
                      </div>
                    </Grid>
                    {oneUserData?.userInfo?.examResult && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input "}>
                          <span>Bali</span>
                          <Typography>
                            {oneUserData?.userInfo?.examResult}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                    {oneUserData?.userInfo?.examPassed && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input "}>
                          <span>Imtihondan o'tgan</span>
                          <Typography>
                            {oneUserData?.userInfo?.examPassed === true
                              ? "Ha"
                              : "Yo'q"}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Tugatgan muassasa joylashgan viloyat</span>
                        <Typography>
                          {oneUserData?.diplomaInfo?.region +
                            ", " +
                            oneUserData?.diplomaInfo?.district}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input"}>
                        <span>Tugatgan yili</span>
                        <Typography>
                          {oneUserData?.diplomaInfo?.graduatedYear
                            ? oneUserData?.diplomaInfo?.graduatedYear
                            : "Mavjud emas"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className={"each_input "}>
                        <span>Emaili</span>
                        <Typography>
                          {oneUserData?.userInfo?.email
                            ? oneUserData?.userInfo?.email
                            : "Email mavjud emas"}
                        </Typography>
                      </div>
                    </Grid>
                    {oneUserData?.userInfo?.zone === "GREEN" ? (
                      <Grid xs={12} md={4}>
                        <div className={"each_input "}>
                          <span>Kontrakt to'lov</span>
                          <Typography>
                            {oneUserData?.userInfo?.contractPaid
                              ? "To'langan"
                              : "To'lanmagan"}
                          </Typography>
                        </div>
                      </Grid>
                    ) : null}
                    {oneUserData?.userInfo?.zone &&
                    oneUserData?.userInfo?.paidAmount != null ? (
                      <Grid xs={12} md={4}>
                        <div className={"each_input "}>
                          <span>Kontrakt to'lov (summasi)</span>
                          <Typography>
                            {oneUserData?.userInfo?.paidAmount}
                          </Typography>
                        </div>
                      </Grid>
                    ) : null}
                    <Grid xs={12} md={4}>
                      <div className={"each_input "}>
                        <span>Izoh</span>
                        <Typography>
                          {oneUserData?.userInfo?.comment
                            ? oneUserData?.userInfo?.comment
                            : "Izoh mavjud emas"}
                        </Typography>
                      </div>
                    </Grid>
                    {oneUserData?.diplomaInfo?.diplomaAttachment?.fullPath && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input"}>
                          <Button
                            href={
                              oneUserData?.diplomaInfo?.diplomaAttachment
                                ?.fullPath
                            }
                            target="_blank"
                          >
                            Diplom nusxasini ko'rish
                          </Button>
                        </div>
                      </Grid>
                    )}
                    {oneUserData?.passportInfo?.passportAttachment && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input"}>
                          <Button
                            href={
                              oneUserData?.passportInfo?.passportAttachment
                                ?.fullPath
                            }
                            target="_blank"
                          >
                            Passport nusxasini ko'rish
                          </Button>
                        </div>
                      </Grid>
                    )}
                    {oneUserData?.diplomaInfo?.tavsiyanomaAttachment && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input"}>
                          <Button
                            href={
                              oneUserData?.diplomaInfo?.tavsiyanomaAttachment
                                ?.fullPath
                            }
                            target="_blank"
                          >
                            Tavsiyanoma nusxasini ko'rish
                          </Button>
                        </div>
                      </Grid>
                    )}
                    {oneUserData?.diplomaInfo?.ieltsAttachment && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input"}>
                          <Button
                            href={
                              oneUserData?.diplomaInfo?.ieltsAttachment
                                ?.fullPath
                            }
                            target="_blank"
                          >
                            {" "}
                            Sertifikat nusxasini ko'rish
                          </Button>
                        </div>
                      </Grid>
                    )}
                    {oneUserData?.diplomaInfo?.mehnatDaftarAttachment && (
                      <Grid xs={12} md={4}>
                        <div className={"each_input"}>
                          <Button
                            href={
                              oneUserData?.diplomaInfo?.mehnatDaftarAttachment
                                ?.fullPath
                            }
                            target="_blank"
                          >
                            Mehnat daftarchasi nusxasini ko'rish
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Typography variant={"h5"}>Yuklanmoqda...</Typography>
                )}
              </Item>
            </Grid>
            <Grid xs={12} md={3}>
              <Item>
                <StyledTextarea
                  aria-label="minimum height"
                  minRows={5}
                  sx={{ width: "100%" }}
                  placeholder="Xabar yuborish"
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                />

                <Button
                  variant="outlined"
                  color="error"
                  sx={{ width: "100%", margin: "3px 0" }}
                  onClick={rejectUser}
                >
                  Rad etmoq
                </Button>
                {oneUserData?.userInfo?.zone !== "GREEN" ? (
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%", margin: "3px 0" }}
                    onClick={acceptUser}
                  >
                    Qabul qilmoq
                  </Button>
                ) : null}

                <Button
                  variant="outlined"
                  color="info"
                  sx={{ width: "100%", margin: "3px 0" }}
                  onClick={sendMessage}
                >
                  SMS yubormoq
                </Button>
                {oneUserData?.fieldInfo?.fieldType !== "MAXSUS_SIRTQI" &&
                  oneUserData?.userInfo?.zone !== "GREEN" && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ width: "100%", margin: "3px 0" }}
                      onClick={sendRuxsatnoma}
                    >
                      Ruxsatnoma berish
                    </Button>
                  )}

                {oneUserData?.userInfo?.zone === "GREEN" ? null : (
                  <Button
                    variant="outlined"
                    color="warning"
                    sx={{ width: "100%", margin: "3px 0" }}
                    onClick={sendContract}
                  >
                    Shartnomani yuborish
                  </Button>
                )}

                <Button
                  variant="outlined"
                  sx={{ width: "100%", margin: "3px 0" }}
                  onClick={sendRegenerateContract}
                >
                  generatsiya shartnoma
                </Button>
              </Item>
              {oneUserData?.userInfo?.zone === "ORANGE" && (
                <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment">
                    Ballni kiriting
                  </InputLabel>
                  <OutlinedInput
                    type="number"
                    id="outlined-adornment"
                    variant={"filled"}
                    value={result}
                    onChange={(e) => setResult(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle"
                          edge="end"
                          color={"primary"}
                          onClick={sendScore}
                        >
                          <SaveIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Ballni kiriting"
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Spinner />
      )}
    </ThemeProvider>
  );
};
export default User;
