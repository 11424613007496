import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  TextField,
  Button,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
} from "@mui/material";

import { style_add } from "../style";
import { useDispatch } from "react-redux";
import { addConfiguration } from "../../../views/redux/globalActions";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

const ConfigurationModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [phone, setPhone] = useState("+998");
  const [examPlace, setExamPlace] = useState(null);
  const [examDate, setExamDate] = useState(null);
  const [currentStudyYear, setCurrentStudyYear] = useState(null);
  const [password, setPassword] = useState("3130080");
  const [mail, setMail] = useState(null);
  const [youtube, setYoutube] = useState(null);
  const [telegram, setTelegram] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const body = {
    name,
    address,
    latitude,
    longitude,
    phone,
    examPlace,
    mail,
    youtube,
    facebook,
    telegram,
    password,
    instagram,
    examDate,
    currentStudyYear,
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleAddConfig = (event) => {
    event.preventDefault();
    dispatch(addConfiguration("/api/v1/config", body));
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style_add}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Parametrlar qo'shish
        </Typography>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <TextField
              label="Nomi"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Manzil"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Kenglik"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Uzunlik"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Telefon raqami"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Imtihon manzili"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={examPlace}
              onChange={(e) => setExamPlace(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                Password
              </InputLabel>
              <FilledInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Instagram"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InstagramIcon />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Facebook"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Telegram"
              value={telegram}
              onChange={(e) => setTelegram(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TelegramIcon />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="input-with-icon-textfield"
              label="Youtube"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTubeIcon />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Imtihon kuni"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={examDate}
              onChange={(e) => setExamDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Hozirgi o'quv yili"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={currentStudyYear}
              onChange={(e) => setCurrentStudyYear(e.target.value)}
            />
          </Grid>
        </Grid>

        <Stack mt={4}>
          <Button variant="outlined" color="success" onClick={handleAddConfig}>
            tahrirlash
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfigurationModal;
