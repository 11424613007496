import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, Button, Stack, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "react-select";
import { style_update } from "../style";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../../views/redux/globalActions";
import "../style.css";
import { educationType, education_shape, language } from "../../Data/regions";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const EditModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.settings);

  const [fieldName, setFieldName] = useState(userData.data.fieldName || null);
  const [bankAccount, setBankAccount] = useState(
    userData.data.bankAccount || null
  );
  const [bankName, setBankName] = useState(userData.data.bankName || null);
  const [bankMFO, setBankMFO] = useState(userData.data.bankMFO || null);
  const [fieldCode, setFieldCode] = useState(userData.data.fieldCode || null);
  const [contractCode, setContractCode] = useState(
    userData.data.contractCode || null
  );
  const [contractCost, setContractCost] = useState(
    userData.data.contractCost || null
  );
  const [contractCostInLetters, setContractCostInLetters] = useState(
    userData.data.contractCostInLetters || null
  );
  const [duration, setDuration] = useState(userData.data.duration || "");
  const [fieldType, setFieldType] = useState(userData.data.fieldType || "");
  const [studyType, setStudyType] = useState(userData.data.studyType || "");
  const [studyLang, setStudyLang] = useState(userData.data.studyLang || "");
  const [examFirst, setExamFirst] = useState(userData.data.examFirst || false);
  const [secondExamSubject, setSecondExamSubject] = useState(
    userData.data.secondExamSubject || ""
  );
  const [firstExamSubject, setFirstExamSubject] = useState(
    userData.data.firstExamSubject || ""
  );

  const body = {
    id: localStorage.getItem("modalId"),
    fieldName,
    bankAccount,
    bankName,
    bankMFO,
    fieldCode,
    contractCode,
    contractCost,
    contractCostInLetters,
    duration,
    fieldType,
    studyType,
    studyLang,
    examFirst,
    firstExamSubject,
    secondExamSubject,
  };

  const handleUpdateItem = (event) => {
    event.preventDefault();
    dispatch(updateData("/api/v1/field/update", body));
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
      }}
    >
      <Box sx={style_update}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Yo'nalishni tahrirlash
        </Typography>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Yo'nalish nomi"
              variant="filled"
              size="small"
              fullWidth
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Yo'nalish raqami"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={fieldCode}
              onChange={(e) => setFieldCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Shartnoma raqami"
              variant="filled"
              size="small"
              fullWidth
              value={contractCode}
              onChange={(e) => setContractCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Shartnoma narxi"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={contractCost}
              onChange={(e) => setContractCost(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Shartnoma raqami (so'zlarda)"
              variant="filled"
              size="small"
              fullWidth
              value={contractCostInLetters}
              onChange={(e) => setContractCostInLetters(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Bank akkounti"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={bankAccount}
              onChange={(e) => setBankAccount(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Bank Nomi"
              variant="filled"
              size="small"
              fullWidth
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Bank MFO"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={bankMFO}
              onChange={(e) => setBankMFO(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-hidden-label-small"
              label="Davomiyligi"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Birinchi imtihon fani"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="text"
              value={firstExamSubject}
              onChange={(e) => setFirstExamSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Ikkinchi imtihon fani"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="text"
              value={secondExamSubject}
              onChange={(e) => setSecondExamSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              placeholder={
                studyLang === "UZB"
                  ? "O'zbek tili"
                  : studyLang === "RUS"
                  ? "Rus tili"
                  : "Ta'lim tili"
              }
              options={language}
              onChange={(option) => setStudyLang(option.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              placeholder={
                studyType === "BAKALAVR"
                  ? "Bakalavr"
                  : studyType === "MAGISTR"
                  ? "Magistratura"
                  : "Ta'lim turini tanlang..."
              }
              options={educationType}
              onChange={(option) => setStudyType(option.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              placeholder={
                fieldType === "MAXSUS_SIRTQI"
                  ? "Maxsus Sirtqi"
                  : fieldType === "KUNDUZGI"
                  ? "Kunduzgi"
                  : fieldType === "SIRTQI"
                  ? "Sirtqi"
                  : "Ta'lim shaklini tanlang..."
              }
              options={education_shape}
              onChange={(option) => setFieldType(option.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2" mb="0" gutterBottom>
                  Imtihon bo'ladimi?
                </Typography>
                <Checkbox
                  {...label}
                  size="small"
                  checked={examFirst}
                  onChange={() => setExamFirst(!examFirst)}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack mt={4}>
          <Button variant="outlined" color="success" onClick={handleUpdateItem}>
            o'zgartirish
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditModal;
