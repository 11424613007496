import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { style_get_one } from "../style";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

export default function GetOneMoadal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const { userData } = useSelector((state) => state.settings);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
      }}
    >
      <Box sx={style_get_one}>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Ma'lumotlar
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Bank nomi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.bankAccount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Bank akkaunti:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.bankName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Bank MFO:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.bankMFO}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Davomiyligi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.duration} yil
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Shartnoma narxi (so'zda):</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.contractCostInLetters}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Shartnoma narxi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.contractCost} so'm
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Shartnoma raqami:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.contractCode}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Yo'nalish raqami:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.fieldCode}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Yo'nalish nomi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.fieldName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Yo'nalish shakli:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.studyType}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Yo'nalish turi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.fieldType}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Imtihoni:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.examFirst ? "Bor" : "Yo'q"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Ko'rsatiladi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.isVisile ? "Ha" : "Yo'q"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Ta'lim tili:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.studyLang === "UZB"
                  ? "O'zbek tili"
                  : "Rus tili"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Birinchi imtihon fani:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.firstExamSubject
                  ? userData?.data?.firstExamSubject
                  : "Fan kiritilmagan!"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">Ikkinchi imtihon fani:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.secondExamSubject
                  ? userData?.data?.secondExamSubject
                  : "Fan kiritilmagan!"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box className={"each_input_modal"}>
              <Typography variant="span">IDsi:</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {userData?.data?.id}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
