import {
  ADD_CONFIGURATION_FAILURE,
  ADD_CONFIGURATION_REQUEST,
  ADD_CONFIGURATION_SUCCESS,
  ADD_DATA_FAILURE,
  ADD_DATA_REQUEST,
  ADD_DATA_SUCCESS,
  CONFIGURATION_DATA_FAILURE,
  CONFIGURATION_DATA_REQUEST,
  CONFIGURATION_DATA_SUCCESS,
  CONTRACT_FIELD_FAILURE,
  CONTRACT_FIELD_REQUEST,
  CONTRACT_FIELD_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  UPDATE_DATA_FAILURE,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  VISIBLE_FIELD_FAILURE,
  VISIBLE_FIELD_REQUEST,
  VISIBLE_FIELD_SUCCESS,
} from "./types";

const initialState = {
  data: [],
  loading: false,
  error: null,
  userData: null,
  config: [],
};
const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
    case ADD_DATA_REQUEST:
    case UPDATE_DATA_REQUEST:
    case FETCH_USER_DATA_REQUEST:
    case ADD_CONFIGURATION_REQUEST:
    case CONFIGURATION_DATA_REQUEST:
    case VISIBLE_FIELD_REQUEST:
    case CONTRACT_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case CONFIGURATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.payload,
      };
    case ADD_DATA_SUCCESS:
    case VISIBLE_FIELD_SUCCESS:
    case CONTRACT_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      };
    case ADD_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        config: [...state.config, action.payload],
      };
    case UPDATE_DATA_SUCCESS:
      const updatedData = state.data.data.map((item) => {
        return item.id === action.payload.id ? action.payload : item;
      });
      return {
        ...state,
        loading: false,
        data: updatedData,
      };

    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case FETCH_DATA_FAILURE:
    case ADD_DATA_FAILURE:
    case UPDATE_DATA_FAILURE:
    case FETCH_USER_DATA_FAILURE:
    case ADD_CONFIGURATION_FAILURE:
    case CONFIGURATION_DATA_FAILURE:
    case VISIBLE_FIELD_FAILURE:
    case CONTRACT_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
