const columns = [
    { id: "firstName", label: "Ismi", minWidth: 60 },
    { id: "lastName", label: "Familiyasi", minWidth: 70 },
    { id: "phone", label: "Telefon raqami", minWidth: 70 },
    { id: "studyType", label: "Ta'lim turi", minWidth: 80 },
    { id: "cratedAt", label: "Sanasi (vaqti bilan)", minWidth: 80 },
    { id: "examResult", label: "Imtihon bali", minWidth: 80 },
    { id: "ruxsatnomaSend", label: "Ruxsatnoma", align: "center", minWidth: 80 },
    { id: "zone", label: "Holati",align:"center", minWidth: 80 },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 580,
    background:"white",
    border:"1px solid",
    borderRadius:"4px",
    boxShadow: 2,
    p: 4,
};

export {columns,style}