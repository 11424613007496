import {
  ADD_CONFIGURATION_FAILURE,
  ADD_CONFIGURATION_REQUEST,
  ADD_CONFIGURATION_SUCCESS,
  ADD_DATA_FAILURE,
  ADD_DATA_REQUEST,
  ADD_DATA_SUCCESS,
  CONFIGURATION_DATA_FAILURE,
  CONFIGURATION_DATA_REQUEST,
  CONFIGURATION_DATA_SUCCESS,
  CONTRACT_FIELD_FAILURE,
  CONTRACT_FIELD_REQUEST,
  CONTRACT_FIELD_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  UPDATE_DATA_FAILURE,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  VISIBLE_FIELD_FAILURE,
  VISIBLE_FIELD_REQUEST,
  VISIBLE_FIELD_SUCCESS,
} from "./types";

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const addDataRequest = () => ({
  type: ADD_DATA_REQUEST,
});

export const addDataSuccess = (item) => {
  return {
    type: ADD_DATA_SUCCESS,
    payload: item,
  };
};

export const addDataFailure = (error) => ({
  type: ADD_DATA_FAILURE,
  payload: error,
});

export const updateDataRequest = () => ({
  type: UPDATE_DATA_REQUEST,
});

export const updateDataSuccess = (updateItem) => {
  return {
    type: UPDATE_DATA_SUCCESS,
    payload: updateItem,
  };
};

export const updateDataFailure = (error) => ({
  type: UPDATE_DATA_FAILURE,
  payload: error,
});

export const fetchUserDataRequest = () => ({
  type: FETCH_USER_DATA_REQUEST,
});

export const fetchUserDataSuccess = (userData) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: userData,
});

export const fetchUserDataFailure = (error) => ({
  type: FETCH_USER_DATA_FAILURE,
  payload: error,
});

export const fetchConfigurationFailure = (error) => ({
  type: CONFIGURATION_DATA_FAILURE,
  payload: error,
});

export const fetchConfigurationRequest = () => ({
  type: CONFIGURATION_DATA_REQUEST,
});

export const fetchConfigurationSuccess = (userData) => ({
  type: CONFIGURATION_DATA_SUCCESS,
  payload: userData,
});

export const addConfigurationFailure = (error) => ({
  type: ADD_CONFIGURATION_FAILURE,
  payload: error,
});

export const addConfigurationSuccess = (configData) => ({
  type: ADD_CONFIGURATION_SUCCESS,
  payload: configData,
});

export const addConfigurationRequest = () => ({
  type: ADD_CONFIGURATION_REQUEST,
});

export const visibleRequest = () => ({
  type: VISIBLE_FIELD_REQUEST,
});

export const visibleSuccess = (data) => ({
  type: VISIBLE_FIELD_SUCCESS,
  payload: data,
});

export const visibleFailure = (error) => ({
  type: VISIBLE_FIELD_FAILURE,
  payload: error,
});

export const contractRequest = () => ({
  type: CONTRACT_FIELD_REQUEST,
});

export const contractSuccess = (data) => ({
  type: CONTRACT_FIELD_SUCCESS,
  payload: data,
});

export const contractFailure = (error) => ({
  type: CONTRACT_FIELD_FAILURE,
  payload: error,
});
