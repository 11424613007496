import Log from "../../views/Log";
import MainPage from "../../views/MainPage";
import MyData from "../../views/Mydata";
import InfoHistory from "../../views/SMSHistory";
import Settings from "../../views/Settings/settings";
import Statistics from "../../views/Statistics/statistics";
import SuggestionHistory from "../../views/SuggestionHistory";
import Update from "../../views/Update";
import User from "../../views/User";
import Configuration from "../../views/configuration";
import Controller from "../../views/controller/controller";
import NotFound from "../NotFoundPage";

const routesPage = [
  {
    path: "/",
    element: <Log />,
  },
  {
    path: "/main",
    element: <MainPage />,
  },
  {
    path: "/main/:id",
    element: <User />,
  },
  {
    path: "/update",
    element: <Update />,
  },
  {
    path: "/statistics",
    element: <Statistics />,
  },
  {
    path: "/mydata",
    element: <MyData />,
  },
  {
    path: "/history",
    element: <InfoHistory />,
  },
  {
    path: "/receive-history",
    element: <SuggestionHistory />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/configuration",
    element: <Configuration />,
  },
  {
    path: "/controller",
    element: <Controller />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routesPage;
