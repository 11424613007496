import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { style_show } from "../style";
import { Button, Stack } from "@mui/material";

import { changeFieldStatus } from "../../../views/redux/globalActions";
import { useDispatch } from "react-redux";

export default function ShowModal({ open, setOpen, isShowed, identification }) {
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);

  const body = {
    url: isShowed ? "/api/v1/field/hide" : "/api/v1/field/activate",
    id: identification,
    active: true,
  };
  const handleChangeField = (event) => {
    event.preventDefault();
    dispatch(changeFieldStatus(body));
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
      }}
    >
      <Box sx={style_show}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Holatni o'zgartirilishiga rozimisiz?
        </Typography>
        {isShowed ? (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Esda tuting, o'zgartirilishni qabul qilish orqali siz ushbu
            ma'lumotlarni foydalanuvchi tarafda ko'rinmasligini va undan
            foydalana olmasligini ta'minlaysiz.
          </Typography>
        ) : (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Esda tuting, o'zgartirilishni qabul qilish orqali siz ushbu
            ma'lumotlarni foydalanuvchi tarafga ko'rsatilishini va undan
            foydalana olishni ta'minlaysiz.
          </Typography>
        )}
        <Stack
          mt={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Button onClick={() => setOpen(false)}>Rad etish</Button>
          <Button onClick={handleChangeField}>Qabul qilish</Button>
        </Stack>
      </Box>
    </Modal>
  );
}
