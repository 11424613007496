export const success_msg = "Muvaffaqiyatli amalga oshirilgan!";

export const GET_DATA = "GET_DATA";
export const CHANGE_GENERATION = "CHANGE_GENERTAION";
export const REGESTRATION = "REGESTRATION";

export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const CONFIGURATION_DATA_SUCCESS = "CONFIGURATION_DATA_SUCCESS";
export const CONFIGURATION_DATA_FAILURE = "CONFIGURATION_DATA_FAILURE";
export const CONFIGURATION_DATA_REQUEST = "CONFIGURATION_DATA_REQUEST";
export const ADD_DATA_REQUEST = "ADD_DATA_REQUEST";
export const ADD_DATA_SUCCESS = "ADD_DATA_SUCCESS";
export const ADD_DATA_FAILURE = "ADD_DATA_FAILURE";
export const UPDATE_DATA_REQUEST = "ADD_DATA_REQUEST";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_FAILURE = "UPDATE_DATA_FAILURE";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";
export const FETCH_USER_DATA_REQUEST = "FETCH_USER_DATA_REQUEST";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const ADD_CONFIGURATION_REQUEST = "ADD_CONFIGURATION_REQUEST";
export const ADD_CONFIGURATION_SUCCESS = "ADD_CONFIGURATION_SUCCESS";
export const ADD_CONFIGURATION_FAILURE = "ADD_CONFIGURATION_FAILURE";

export const VISIBLE_FIELD_REQUEST = "VISIBLE_FIELD_REQUEST";
export const VISIBLE_FIELD_FAILURE = "VISIBLE_FIELD_FAILURE";
export const VISIBLE_FIELD_SUCCESS = "VISIBLE_FIELD_SUCCESS";

export const CONTRACT_FIELD_REQUEST = "CONTRACT_FIELD_REQUEST";
export const CONTRACT_FIELD_FAILURE = "CONTRACT_FIELD_FAILURE";
export const CONTRACT_FIELD_SUCCESS = "CONTRACT_FIELD_SUCCESS";
