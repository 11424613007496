import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, Button, Stack, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "react-select";
import { style_add } from "../style";

import { useDispatch } from "react-redux";
import { addData } from "../../../views/redux/globalActions";
import { educationType, education_shape, language } from "../../Data/regions";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const AddModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const [fieldName, setFieldName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankMFO, setBankMFO] = useState("");
  const [fieldCode, setFieldCode] = useState("");
  const [contractCode, setContractCode] = useState("");
  const [contractCost, setContractCost] = useState(10000000);
  const [contractCostInLetters, setContractCostInLetters] = useState("");
  const [duration, setDuration] = useState(4);
  const [fieldType, setFieldType] = useState("");
  const [studyType, setStudyType] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [examFirst, setExamFirst] = useState(false);
  const [studyLang, setStudyLang] = useState("");
  const [firstExamSubject, setFirstExamSubject] = useState("");
  const [secondExamSubject, setSecondExamSubject] = useState("");

  const body = {
    fieldName,
    bankAccount,
    bankName,
    bankMFO,
    fieldCode,
    contractCode,
    contractCost,
    contractCostInLetters,
    duration,
    fieldType,
    studyType,
    studyLang,
    isVisible,
    examFirst,
    firstExamSubject,
    secondExamSubject,
  };

  const handleAddItem = (event) => {
    event.preventDefault();
    dispatch(addData("/api/v1/field/add", body));
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style_add}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Yo'nalish qo'shish
        </Typography>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <TextField
              label="Yo'nalish nomi"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Yo'nalish raqami"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              type="number"
              fullWidth
              value={fieldCode}
              onChange={(e) => setFieldCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Shartnoma raqami"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={contractCode}
              onChange={(e) => setContractCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Shartnoma narxi"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={contractCost}
              onChange={(e) => setContractCost(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Shartnoma narxi (so'zlarda)"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={contractCostInLetters}
              onChange={(e) => setContractCostInLetters(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bank akkounti"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              type="number"
              fullWidth
              value={bankAccount}
              onChange={(e) => setBankAccount(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bank Nomi"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bank MFO"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={bankMFO}
              onChange={(e) => setBankMFO(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Davomiyligi"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Birinchi imtihon fani"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="text"
              value={firstExamSubject}
              onChange={(e) => setFirstExamSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Ikkinchi imtihon fani"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              fullWidth
              type="text"
              value={secondExamSubject}
              onChange={(e) => setSecondExamSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              placeholder={"Ta'lim tilini tanlang..."}
              options={language}
              onChange={(option) => setStudyLang(option.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              placeholder="Ta'lim turini tanlang..."
              options={educationType}
              onChange={(option) => setStudyType(option.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              placeholder="Ta'lim shaklini tanlang..."
              options={education_shape}
              onChange={(option) => setFieldType(option.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" alignItems="center">
                <Checkbox
                  {...label}
                  checked={isVisible}
                  size="small"
                  onChange={() => setIsVisible(!isVisible)}
                />
                <Typography variant="subtitle2" mb="0" gutterBottom>
                  Ko'rsatilsin
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Checkbox
                  {...label}
                  size="small"
                  checked={examFirst}
                  onChange={() => setExamFirst(!examFirst)}
                />
                <Typography variant="subtitle2" mb="0" gutterBottom>
                  Imtihon
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack mt={4}>
          <Button variant="outlined" color="success" onClick={handleAddItem}>
            qo'shish
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddModal;
