import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { style_show } from "../style";
import {
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";

import {
  addContract,
  changeFieldStatus,
} from "../../../views/redux/globalActions";
import { useDispatch } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";

import "./contract-sum.css";
import { useState } from "react";
export default function ContractSum({
  open,
  setOpen,
  identification,
  setChange,
}) {
  const dispatch = useDispatch();
  const [sum, setSum] = useState(10000000);
  const handleClose = () => setOpen(false);

  const body = {
    url: "/api/v1/admin/user/set/paid/summ",
    uuid: identification,
    amount: sum,
  };
  const handleChangeField = (event) => {
    event.preventDefault();
    dispatch(addContract(body));
    setChange((prev) => !prev);
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style_show}>
        <Box className="close-btn" onClick={() => setOpen(false)}>
          <Tooltip title="Chiqish">
            <IconButton>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box mt={3} mb={2}>
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="filled-adornment-amount">
              Kontrakt summasi
            </InputLabel>
            <FilledInput
              type="number"
              id="filled-adornment-amount"
              value={sum}
              onChange={(e) => setSum(e.target.value)}
              startAdornment={
                <InputAdornment position="start">So'm: </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Stack
          mt={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Button onClick={handleChangeField} variant="outlined">
            Yuborish
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
