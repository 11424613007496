import { combineReducers, applyMiddleware, legacy_createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducer from "./reducers";
import settingsReducer from "./settingsReducer";

const reducers = combineReducers({
  FullReducer: reducer,
  settings: settingsReducer,
});

const store = legacy_createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
export default store;
